.flag {
    display: inline-block;
    width: 24px;
    height: 24px;
}

.ar {
    background-position: -216px 0 !important;
}

.pg {
    background-position: -216px -240px !important;
}

.cm {
    background-position: -264px -48px !important;
}

.gn {
    background-position: -24px -120px !important;
}

.ee {
    background-position: -264px -72px !important;
}

.ga {
    background-position: -192px -96px !important;
}

.tt {
    background-position: -168px -312px !important;
}

.gg {
    background-position: -288px -96px !important;
}

.vg {
    background-position: -72px -336px !important;
}

.cv {
    background-position: 0 -72px !important;
}

.ai {
    background-position: -96px 0 !important;
}

.ck {
    background-position: -216px -48px !important;
}

.cy {
    background-position: -48px -72px !important;
}

.vn {
    background-position: -120px -336px !important;
}

.gd {
    background-position: -240px -96px !important;
}

.am {
    background-position: -144px 0 !important;
}

.mh {
    background-position: -192px -192px !important;
}

.ss {
    background-position: -144px -288px !important;
}

.ye {
    background-position: -216px -336px !important;
}

.fr {
    background-position: -168px -96px !important;
}

.ht {
    background-position: -288px -120px !important;
}

.ws {
    background-position: -192px -336px !important;
}

.mu {
    background-position: -72px -216px !important;
}

.mm {
    background-position: -264px -192px !important;
}

.ie {
    background-position: 0 -144px !important;
}

.gr {
    background-position: -72px -120px !important;
}

.gt {
    background-position: -120px -120px !important;
}

.ba {
    background-position: 0 -24px !important;
}

.ua {
    background-position: -264px -312px !important;
}

.kg {
    background-position: -312px -144px !important;
}

.mc {
    background-position: -72px -192px !important;
}

.mw {
    background-position: -120px -216px !important;
}

.cf {
    background-position: -120px -48px !important;
}

.cn {
    background-position: -288px -48px !important;
}

.dk {
    background-position: -144px -72px !important;
}

.ve {
    background-position: -48px -336px !important;
}

.rs {
    background-position: -120px -264px !important;
}

.np {
    background-position: -24px -240px !important;
}

.bw {
    background-position: 0 -48px !important;
}

.cg {
    background-position: -144px -48px !important;
}

.kz {
    background-position: -144px -168px !important;
}

.do {
    background-position: -192px -72px !important;
}

.de {
    background-position: -96px -72px !important;
}

.ad {
    background-position: 0 0 !important;
}

.rw {
    background-position: -168px -264px !important;
}

.tz {
    background-position: -240px -312px !important;
}

.be {
    background-position: -72px -24px !important;
}

.si {
    background-position: -24px -288px !important;
}

.iq {
    background-position: -96px -144px !important;
}

.fm {
    background-position: -120px -96px !important;
}

.ge {
    background-position: -264px -96px !important;
}

.ne {
    background-position: -264px -216px !important;
}

.mq {
    background-position: -360px -192px !important;
}

.kw {
    background-position: -96px -168px !important;
}

.gl {
    background-position: -360px -96px !important;
}

.cz {
    background-position: -72px -72px !important;
}

.sh {
    background-position: -336px -264px !important;
}

.fi {
    background-position: -48px -96px !important;
}

.mn {
    background-position: -288px -192px !important;
}

.bd {
    background-position: -48px -24px !important;
}

.to {
    background-position: -120px -312px !important;
}

.mk {
    background-position: -216px -192px !important;
}

.ae {
    background-position: -24px 0 !important;
}

.fo {
    background-position: -144px -96px !important;
}

.ls {
    background-position: -312px -168px !important;
}

.in {
    background-position: -72px -144px !important;
}

.ec {
    background-position: -240px -72px !important;
}

.pt {
    background-position: 0 -264px !important;
}

.pf {
    background-position: -192px -240px !important;
}

.bb {
    background-position: -24px -24px !important;
}

.pl {
    background-position: -288px -240px !important;
}

.na {
    background-position: -216px -216px !important;
}

.jm {
    background-position: -216px -144px !important;
}

.me {
    background-position: -120px -192px !important;
}

.dm {
    background-position: -168px -72px !important;
}

.bz {
    background-position: -48px -48px !important;
}

.sl {
    background-position: -360px -264px !important;
}

.lc {
    background-position: -216px -168px !important;
}

.zw {
    background-position: -312px -336px !important;
}

.kn {
    background-position: -24px -168px !important;
}

.sy {
    background-position: -216px -288px !important;
}

.gw {
    background-position: -168px -120px !important;
}

.cw {
    background-position: -24px -72px !important;
}

.ky {
    background-position: -120px -168px !important;
}

.ag {
    background-position: -72px 0 !important;
}

.sz {
    background-position: -240px -288px !important;
}

.bg {
    background-position: -120px -24px !important;
}

.hk {
    background-position: -216px -120px !important;
}

.yt {
    background-position: -240px -336px !important;
}

.ni {
    background-position: -336px -216px !important;
}

.gm {
    background-position: 0 -120px !important;
}

.az {
    background-position: -360px 0 !important;
}

.gs {
    background-position: -96px -120px !important;
}

.it {
    background-position: -168px -144px !important;
}

.es {
    background-position: -360px -72px !important;
}

.mf {
    background-position: -144px -192px !important;
}

.td {
    background-position: -288px -288px !important;
}

.tw {
    background-position: -216px -312px !important;
}

.mt {
    background-position: -48px -216px !important;
}

.pn {
    background-position: -312px -240px !important;
}

.wf {
    background-position: -168px -336px !important;
}

.us {
    background-position: -312px -312px !important;
}

.ml {
    background-position: -240px -192px !important;
}

.gi {
    background-position: -336px -96px !important;
}

.zm {
    background-position: -288px -336px !important;
}

.tl {
    background-position: -48px -312px !important;
}

.dj {
    background-position: -120px -72px !important;
}

.pw {
    background-position: -24px -264px !important;
}

.sg {
    background-position: -312px -264px !important;
}

.li {
    background-position: -240px -168px !important;
}

.vi {
    background-position: -96px -336px !important;
}

.hr {
    background-position: -264px -120px !important;
}

.ms {
    background-position: -24px -216px !important;
}

.kp {
    background-position: -48px -168px !important;
}

.sa {
    background-position: -192px -264px !important;
}

.ly {
    background-position: -24px -192px !important;
}

.cr {
    background-position: -336px -48px !important;
}

.nr {
    background-position: -48px -240px !important;
}

.sm {
    background-position: -48px -288px !important;
}

.za {
    background-position: -264px -336px !important;
}

.mx {
    background-position: -144px -216px !important;
}

.gy {
    background-position: -192px -120px !important;
}

.id {
    background-position: -360px -120px !important;
}

.py {
    background-position: -48px -264px !important;
}

.pk {
    background-position: -264px -240px !important;
}

.ax {
    background-position: -336px 0 !important;
}

.bj {
    background-position: -192px -24px !important;
}

.dz {
    background-position: -216px -72px !important;
}

.ps {
    background-position: -360px -240px !important;
}

.gq {
    background-position: -48px -120px !important;
}

.tm {
    background-position: -72px -312px !important;
}

.bf {
    background-position: -96px -24px !important;
}

.my {
    background-position: -168px -216px !important;
}

.sd {
    background-position: -264px -264px !important;
}

.jo {
    background-position: -240px -144px !important;
}

.hu {
    background-position: -312px -120px !important;
}

.mp {
    background-position: -336px -192px !important;
}

.bo {
    background-position: -288px -24px !important;
}

.nl {
    background-position: -360px -216px !important;
}

.tf {
    background-position: -312px -288px !important;
}

.ma {
    background-position: -48px -192px !important;
}

.la {
    background-position: -168px -168px !important;
}

.jp {
    background-position: -264px -144px !important;
}

.fj {
    background-position: -72px -96px !important;
}

.sc {
    background-position: -240px -264px !important;
}

.er {
    background-position: -336px -72px !important;
}

.kh {
    background-position: -336px -144px !important;
}

.lv {
    background-position: 0 -192px !important;
}

.sb {
    background-position: -216px -264px !important;
}

.ao {
    background-position: -192px 0 !important;
}

.eu {
    background-position: -24px -96px !important;
}

.lr {
    background-position: -288px -168px !important;
}

.tc {
    background-position: -264px -288px !important;
}

.bt {
    background-position: -360px -24px !important;
}

.bl {
    background-position: -216px -24px !important;
}

.tr {
    background-position: -144px -312px !important;
}

.km {
    background-position: 0 -168px !important;
}

.je {
    background-position: -192px -144px !important;
}

.is {
    background-position: -144px -144px !important;
}

.sr {
    background-position: -120px -288px !important;
}

.tv {
    background-position: -192px -312px !important;
}

.ph {
    background-position: -240px -240px !important;
}

.tj {
    background-position: 0 -312px !important;
}

.mv {
    background-position: -96px -216px !important;
}

.al {
    background-position: -120px 0 !important;
}

.vu {
    background-position: -144px -336px !important;
}

.cd {
    background-position: -96px -48px !important;
}

.aw {
    background-position: -312px 0 !important;
}

.nu {
    background-position: -72px -240px !important;
}

.bi {
    background-position: -168px -24px !important;
}

.uy {
    background-position: -336px -312px !important;
}

.ug {
    background-position: -288px -312px !important;
}

.ci {
    background-position: -192px -48px !important;
}

.tn {
    background-position: -96px -312px !important;
}

.nz {
    background-position: -96px -240px !important;
}

.no {
    background-position: 0 -240px !important;
}

.hn {
    background-position: -240px -120px !important;
}

.an {
    background-position: -168px 0 !important;
}

.gh {
    background-position: -312px -96px !important;
}

.pr {
    background-position: -336px -240px !important;
}

.pa {
    background-position: -144px -240px !important;
}

.vc {
    background-position: -24px -336px !important;
}

.ng {
    background-position: -312px -216px !important;
}

.il {
    background-position: -24px -144px !important;
}

.au {
    background-position: -288px 0 !important;
}

.qa {
    background-position: -72px -264px !important;
}

.mz {
    background-position: -192px -216px !important;
}

.tg {
    background-position: -336px -288px !important;
}

.st {
    background-position: -168px -288px !important;
}

.ki {
    background-position: -360px -144px !important;
}

.tk {
    background-position: -24px -312px !important;
}

.as {
    background-position: -240px 0 !important;
}

.eg {
    background-position: -288px -72px !important;
}

.nc {
    background-position: -240px -216px !important;
}

.fk {
    background-position: -96px -96px !important;
}

.eh {
    background-position: -312px -72px !important;
}

.af {
    background-position: -48px 0 !important;
}

.uk {
    background-position: -216px -96px !important;
}

.cu {
    background-position: -360px -48px !important;
}

.va {
    background-position: 0 -336px !important;
}

.sn {
    background-position: -72px -288px !important;
}

.sv {
    background-position: -192px -288px !important;
}

.th {
    background-position: -360px -288px !important;
}

.ru {
    background-position: -144px -264px !important;
}

.ic {
    background-position: -336px -120px !important;
}

.ch {
    background-position: -168px -48px !important;
}

.lu {
    background-position: -360px -168px !important;
}

.so {
    background-position: -96px -288px !important;
}

.bn {
    background-position: -264px -24px !important;
}

.at {
    background-position: -264px 0 !important;
}

.md {
    background-position: -96px -192px !important;
}

.lb {
    background-position: -192px -168px !important;
}

.im {
    background-position: -48px -144px !important;
}

.by {
    background-position: -24px -48px !important;
}

.uz {
    background-position: -360px -312px !important;
}

.ke {
    background-position: -288px -144px !important;
}

.nf {
    background-position: -288px -216px !important;
}

.bm {
    background-position: -240px -24px !important;
}

.mr {
    background-position: 0 -216px !important;
}

.ro {
    background-position: -96px -264px !important;
}

.bs {
    background-position: -336px -24px !important;
}

.kr {
    background-position: -72px -168px !important;
}

.et {
    background-position: 0 -96px !important;
}

.om {
    background-position: -120px -240px !important;
}

.bh {
    background-position: -144px -24px !important;
}

.br {
    background-position: -312px -24px !important;
}

.gu {
    background-position: -144px -120px !important;
}

.pe {
    background-position: -168px -240px !important;
}

.se {
    background-position: -288px -264px !important;
}

.ca {
    background-position: -72px -48px !important;
}

.cl {
    background-position: -240px -48px !important;
}

.mo {
    background-position: -312px -192px !important;
}

.sk {
    background-position: 0 -288px !important;
}

.ir {
    background-position: -120px -144px !important;
}

.co {
    background-position: -312px -48px !important;
}

.mg {
    background-position: -168px -192px !important;
}

.lk {
    background-position: -264px -168px !important;
}

.lt {
    background-position: -336px -168px !important;
}