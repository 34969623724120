/* Mediterranean */
.destinationArea-0 {
	top: 3rem;         
	left: 19rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationAreaHoverDestinationList-0 {
	color: white;  
	background-color: #FC4C02; 
}
.destinationArea-0:hover {
	color: white;  
	background-color: #FC4C02;     
}
.destinationAreaTextColor-0:hover {
	color: #FC4C02 !important;
	font-weight: 400;
}

/* North Europe */
.destinationArea-1 { 
	top: -1rem;         
	left: 21rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-1:hover {
	color: white; 
	background-color: #707375;      
}
.destinationAreaHoverDestinationList-1 {
	color: white; 
	background-color: #707375;      
}
.destinationAreaTextColor-1:hover {
	color: #707375 !important;
	font-weight: 400;
}

/* Caribbean and Antilles */
.destinationArea-2 {
	top: 3rem;         
	left: 7rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-2:hover {
	color: white; 
	background-color: #008894;      
}
.destinationAreaHoverDestinationList-2 {
	color: white; 
	background-color: #008894;      
}
.destinationAreaTextColor-2:hover {
	color: #008894 !important;
	font-weight: 400;
}

/* Dubai, Abu Dhabi & Qatar */
.destinationArea-3 { 
	top: 0rem;         
	left: 15rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-3:hover {
	color: white; 
	background-color:  #635547;     
}
.destinationAreaHoverDestinationList-3 {
	color: white; 
	background-color: #635547;      
}
.destinationAreaTextColor-3:hover {
	color: #635547 !important;
	font-weight: 400;

}

/* South America */
.destinationArea-4 {
	top: 3rem;         
	left: 13rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-4:hover {
	color: white; 
	background-color:  #649A3F;      
}
.destinationAreaHoverDestinationList-4 {
	color: white; 
	background-color: #649A3F;      
}
.destinationAreaTextColor-4:hover {
	color: #649A3F !important;
	font-weight: 400;
}

/* South Africa */
.destinationArea-5 {
	top: 0rem;         
	left: 21rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-5:hover {
	color: white; 
	background-color:  #DCB640;      
}
.destinationAreaHoverDestinationList-5 {
	color: white; 
	background-color: #DCB640;      
}
.destinationAreaTextColor-5:hover {
	color: #DCB640 !important;
	font-weight: 400;
}

/* Asia */
.destinationArea-6 { 
	top: -8rem;         
	left: 32rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-6:hover {
	color: white; 
	background-color:  #ECAA00;      
}
.destinationAreaHoverDestinationList-6 {
	color: white; 
	background-color: #ECAA00;      
}
.destinationAreaTextColor-6:hover {
	color: #ECAA00 !important;
	font-weight: 400;
}

/* United States & Canada */
.destinationArea-7 {
	top: -10rem;         
	left: 6rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-7:hover {
	color: white; 
	background-color:  #991C26;      
}
.destinationAreaHoverDestinationList-7 {
	color: white; 
	background-color: #991C26;      
}
.destinationAreaTextColor-7:hover {
	color: #991C26 !important;
	font-weight: 400;
}

/* MSC Grand Voyages */
.destinationArea-8 {
	top: -7.5rem;         
	left: 12rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-8:hover {
	color: white; 
	background-color:  #004071;      
}
.destinationAreaHoverDestinationList-8 {
	color: white; 
	background-color: #004071;      
}
.destinationAreaTextColor-8:hover {
	color: #004071 !important;
	font-weight: 400;
}

/* Msc World Cruise */
.destinationArea-9 { 
	top: -7rem;         
	left: 28rem; 
	transition: transform 0.3s ease, background-color 0.1s ease;
}
.destinationArea-9:hover {
	color: white; 
	background-color:  #000033;      
}
.destinationAreaHoverDestinationList-9 {
	color: white; 
	background-color: #000033;      
}
.destinationAreaTextColor-9:hover {
	color: #000033 !important;
	font-weight: 400;
}

/* Mini Cruises, not represented in the map */
.destinationArea-10 {
	display:none;
}

/* Long Cruises, not represented in the map */
.destinationArea-11 {
	display:none;
}

/* Ocean Cay MSC Marine Reserve, not represented in the map */
.destinationArea-12 {
	display:none;
}