@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
	--foreground-background: #f1f1f1;
	--text-main-color: #000033;
}

/* Uncomment if using dark mode */
/*
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-background: #000033;
    --text-main-color: #ffffff;
  }
}
*/

body {
	color: var(--text-main-color);
	background-color: var(--foreground-background);
}

@mixin line-clamp($lines) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
}

.line-clamp-1 {
	@include line-clamp(1);
}

.line-clamp-2 {
	@include line-clamp(2);
}

.line-clamp-3 {
	@include line-clamp(3);
}

.react-datepicker-popper {
	.react-datepicker {
		display: flex;
		flex-direction: column;
		width: 100%;
		top: -10px; // Removing the gap of the triangle
		left: -24px;
		border-radius: 0;
		border: none;
		padding: 24px;

		@media screen and (min-width: 768px) {
			flex-direction: row;
		}
		&.datePickerPadding {
			padding-top: 48px;
		}
		&__triangle {
			display: none;
		}
		&__navigation {
			top: 72px;
			transform: translateY(-50%);
		}
		&__month-container {
			flex: 1;
			width: 100%;
		}
		&__header {
			background-color: white;
			border-bottom: none;
		}
		&__current-month {
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 16px;
		}
		&__week,
		&__day-names {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 1;
		}
		&__day-name,
		&__day,
		&__time-name {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			border-radius: 0;
			background-color: transparent;
			color: #000033;
			margin-right: 0;
			margin-left: 0;
			transition: all 0.4s ease-in-out;
			@media screen and (min-width: 1535px) {
				width: 35px;
				height: 35px;
			}
			&:hover {
				border-radius: 50%;
			}
			&--in-range {
				border-radius: 0;
				background-color: rgba(#000033, 0.1);
				&:hover {
					background-color: rgba(#000033, 0.1);
					border-radius: 0;
				}
			}
			&--range-start,
			&--range-end {
				position: relative;
				border-radius: 50%;
				background-color: #4892e0;
				color: white;
				&:before {
					content: '';
					position: absolute;
					z-index: -1;
					top: 0;
					bottom: 0;
					background-color: rgba(#000033, 0.1);
					width: 50%;
				}
			}
			&--range-start {
				&::before {
					right: 0;
				}
			}
			&--range-end {
				&::before {
					left: 0;
				}
			}
			&--disabled {
				text-decoration: line-through;
				color: #dfe4ea;
				cursor: not-allowed;
				&:hover {
					background-color: #f1f1f1;
					border-radius: 0;
				}
			}
		}
		&__month {
			&-text,
			.react-datepicker__quarter-text {
				width: calc(33% - 8px) !important;
				font-size: 14px;
				padding: 4px;
				margin: 4px;
			}
		}
		.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range, .react-datepicker__day--outside-month) {
			border-radius: 0;
			background-color: rgba(#000033, 0.1);
		}
		.react-datepicker__day--outside-month {
			background-color: transparent;
		}
	}
}

input {
	&.selectComponentCheckbox {
		&[type='checkbox'] {
			appearance: none;
			width: 18px;
			height: 18px;
			background-color: transparent;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M14.25 2.25H3.75C2.9175 2.25 2.25 2.9175 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V3.75C15.75 2.9175 15.075 2.25 14.25 2.25ZM14.25 3.75V14.25H3.75V3.75H14.25Z' fill='%236B6B87'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center;
			&:checked {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M14.25 14.25H3.75V3.75H11.25V2.25H3.75C2.9175 2.25 2.25 2.9175 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V8.25H14.25M5.9325 7.56L4.875 8.625L8.25 12L15.75 4.5L14.6925 3.435L8.25 9.8775L5.9325 7.56Z' fill='%23035FBF'/%3E%3C/svg%3E");
			}
		}
	}
}

/* Range thumb (the draggable handle) */
.range-input::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	background: white;
	border: 1px solid #000033;
	border-radius: 50%;
	pointer-events: all;
	cursor: pointer;
}
.range-input::-moz-range-thumb {
	width: 18px;
	height: 18px;
	background: white;
	border: 1px solid #000033;
	border-radius: 50%;
	pointer-events: all;
	cursor: pointer;
}
.range-input::-ms-thumb {
	width: 18px;
	height: 18px;
	background: white;
	border: 1px solid #000033;
	border-radius: 50%;
	pointer-events: all;
	cursor: pointer;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}
